import { CloseOutlined } from "@ant-design/icons";
import { Row, Typography, Button, Switch, Tooltip } from "antd";
import CustomModal from "Common/Components/CustomAntD/Modal";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ManageFileSDK from "sdk/ManageFileSDK";
import { buttonText, ManageFiles_Constants } from "utility/constants";
import useCustomBreakpoints from "utility/UseCustomBreakpoints";
import RefreshIntervalSupport from "./RefreshIntervalSupport";

const RefreshIntervalModal = ({
  refreshIntervalModal,
  setRefreshIntervalModal,
  setRefreshIntervalSuccessModal,
  repeatIntervalForm,
  setRepeatIntervalForm,
}) => {
  const { Title } = Typography;
  const screens = useCustomBreakpoints();
  const { t } = useTranslation();

  const { Refresh_Interval_Modal } = ManageFiles_Constants?.Upload_Tab;
  const { Cancel, Save } = buttonText;

  const { Refresh_Interval_Title } = Refresh_Interval_Modal;

  const [loading, setLoading] = useState(false);

  const handleCloseRefreshIntervalSwitch = (checkedFlag) => {
    if (checkedFlag) {
      setRepeatIntervalForm((pS) => ({
        ...pS,
        interval_type: "NONE",
      }));
    } else {
      setRepeatIntervalForm((pS) => ({
        ...pS,
        interval_type: "DAILY",
      }));
    }
  };

  const handleUpdateRefreshInterval = () => {
    setLoading(true);
    ManageFileSDK.updateRefreshHandler(repeatIntervalForm)
      .then(() => {
        setRefreshIntervalSuccessModal(true);
        setRefreshIntervalModal(false);
      })
      .finally(() => setLoading(false));
  };

  return (
    <CustomModal
      visible={refreshIntervalModal}
      closable={true}
      centered
      onCancel={() => setRefreshIntervalModal(false)}
      footer={null}
      width={
        screens?.xs || screens?.sm || screens?.md || screens?.lg ? "75%" : "35%"
      }
      height={"40%"}
      className="refresh-interval-modal"
    >
      <Row justify="space-between" align="middle">
        <Title level={3} className="mb-0">
          {t(Refresh_Interval_Title)}
        </Title>
        <Tooltip title={"Enable/Disable refresh interval"} color="#F5DB5D">
          <Switch
            onChange={(e) => handleCloseRefreshIntervalSwitch(e)}
            checkedChildren={"On"}
            unCheckedChildren={"Off"}
            checked={repeatIntervalForm?.interval_type === "NONE"}
            className="z-1"
          />
        </Tooltip>
      </Row>
      <div className="d-flex justify-content-center ">
        <RefreshIntervalSupport
          repeatIntervalForm={repeatIntervalForm}
          setRepeatIntervalForm={setRepeatIntervalForm}
          type="edit"
        />
      </div>

      <Row className="gap-05" justify="end">
        <Button
          type="primary"
          className="black-btn"
          onClick={() => setRefreshIntervalModal(false)}
        >
          {t(Cancel)}
        </Button>
        <Button
          type="primary"
          onClick={() => handleUpdateRefreshInterval()}
          loading={loading}
        >
          {t(Save)}
        </Button>
      </Row>
    </CustomModal>
  );
};

export default RefreshIntervalModal;
